import { useState, useEffect } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import { baseUrl, baseUrladmin } from "../../../assets/js/config/config.js";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import axios from "axios";
import { useLocation } from "react-router-dom";
import "../style.css";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";

function EditNewDevice() {
    const AccessToken = localStorage.getItem("accessToken");
    const { register, handleSubmit, formState: { errors } } = useForm();
    const navigate = useNavigate();
    const [editdata, setEditdata] = useState([]);
    const { state } = useLocation();


    useEffect(() => {
            // const bodyFormData = {
            //     companyMasterId: state.companyMasterId,
            // };
            axios({
                method: "get",
                url: baseUrladmin + "device-master"+'/'+state.deviceId,
                //data: bodyFormData,
                config: {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json; charset=utf-8",
                    },
                },
            }).then((response) => response)
                .then((data) => {
                    setEditdata(data.data.data);
                })
                .catch((err) => {
                    console.log(err.message);
                });
    }, []);
    const onSubmit = (data) => {
        const bodyFormData = {
            "deviceName": data.deviceName ? data.deviceName : editdata.deviceName,
            "deviceAddress": data.deviceAddress ? data.deviceAddress : editdata.deviceAddress,
        };
        axios({
            method: "put",
            url: baseUrladmin + "device-master"+'/'+state.deviceId,
            data: bodyFormData,
            config: {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json; charset=utf-8",
                },
            },
        })
            .then((data) => {
                if (data.data.status == 200) {
                    alert("Device Updated Successfully");
                    navigate("/getNewDevice");
                }
                else {
                    alert(data.data.message);
                    window.location.reload(false);
                }
            })
            .catch((err) => {
                console.log(err.message);
            });
    }
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Grid container spacing={6}><Grid item xs={12}>
                <Card>
                    <MDBox
                        mx={2}
                        mt={3}
                        py={3}
                        px={2}
                        variant="gradient"
                        bgColor="info"
                        borderRadius="lg"
                        coloredShadow="info"
                    >
                        <MDTypography variant="h6" color="white">
                            Edit Device
                        </MDTypography>
                    </MDBox>
                    <div className='App container fpd col-6'>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className='row' style={{ width: "900px" }}>
                                <div className='mb-1'>
                                    <label htmlFor='deviceAddress'>Device Name</label>
                                    <input
                                        className={`form-control ${errors.deviceAddress ? 'is-invalid' : ''}`}
                                        type='text'
                                        name='deviceAddress'  defaultValue={editdata.deviceAddress}
                                        {...register("deviceAddress", { required: true })}
                                    />
                                </div>
                                <div className='mb-1'>
                                    <label htmlFor='deviceAddress'>Device Address</label>
                                    <input
                                        className={`form-control ${errors.deviceAddress ? 'is-invalid' : ''}`}
                                        type='text'
                                        name='deviceAddress'  defaultValue={editdata.deviceAddress}
                                        {...register("deviceAddress", { required: true })}
                                    />
                                </div>
                            </div>
                            <div className='mb-3'>
                                <MDButton variant="outlined" type="submit" color="info" size="small">
                                    Update
                                </MDButton>
                            </div>
                        </form>
                    </div>
                </Card>
            </Grid>
            </Grid>
        </DashboardLayout>
    );
}

export default EditNewDevice;
