import { useState, useEffect } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import { baseUrl, baseUrladmin } from "../../../assets/js/config/config.js";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import axios from "axios";
import { useLocation } from "react-router-dom";
import "../style.css";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";

function AddCollege() {
    const AccessToken = localStorage.getItem("accessToken");
    const { register, handleSubmit, formState: { errors } } = useForm();
    const navigate = useNavigate();
    const [collegeGroups, setCollegeGroups] = useState([]);
    const [collegeLocations, setCollegeLocations] = useState([]);
    const [collegeGroupId, setcolGroup] = useState(localStorage.getItem("collegeGroupId") || "");
    const [colLocationId, setcolLocation] = useState(localStorage.getItem("colLocationId") || "");

    const handlcolGroup = (e) => {
        fetchCollegeGroups();
        setcolGroup(e.target.value);
        localStorage.setItem("collegeGroupId", e.target.value);
        fetchCollegeLocations(e.target.value);
     };
   const handlcolLocation = (e) => {
       setcolLocation(e.target.value);
    };
    const fetchCollegeGroups = () => {
        fetch(baseUrladmin + "college-groups", {
            headers: {
                Authorization: `Bearer ${AccessToken}`,
                "Content-type": "application/json; charset=UTF-8",
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.status == 200) {
                    setCollegeGroups(data.data);
                }
            })
            .catch((err) => {
                console.log(err.message);
            });
    }
    const fetchCollegeLocations = (colGroup) => {
        let colGroup1 =  localStorage.getItem("collegeGroupId") || colGroup ;
        fetch(baseUrladmin + "college-locations/college-group/"+colGroup1, {
            headers: {
                Authorization: `Bearer ${AccessToken}`,
                "Content-type": "application/json; charset=UTF-8",
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.status == 200) {
                    setCollegeLocations(data.data);
                }
            })
            .catch((err) => {
                console.log(err.message);
            });
    }
    const onSubmit = (data) => {
        const bodyFormData = {
            "collegeName": data.collegeName,
            "phoneNumber": data.phoneNumber,
            "collegeLocationId" : colLocationId
        };
        axios({
            method: "post",
            url: baseUrladmin + "college-master",
            data: bodyFormData,
            config: {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json; charset=utf-8",
                     companyId: localStorage.getItem("companyId")
                },
            },
        })
            .then((data) => {
                if (data.data.status == 200) {
                    alert("College Added Successfully");
                    navigate("/getCollege");
                }
                else {
                    alert(data.data.message);
                    window.location.reload(false);
                }
            })
            .catch((err) => {
                console.log(err.message);
            });
    }
    useEffect(() => {
        fetchCollegeGroups();
        fetchCollegeLocations();
    }, []);
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Grid container spacing={6}><Grid item xs={12}>
                <Card>
                    <MDBox
                        mx={2}
                        mt={3}
                        py={3}
                        px={2}
                        variant="gradient"
                        bgColor="info"
                        borderRadius="lg"
                        coloredShadow="info"
                    >
                        <MDTypography variant="h6" color="white">
                            Add College
                        </MDTypography>
                    </MDBox>
                    <div className='App container fpd col-6'>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className='row' style={{ width: "900px" }}>
                            <div className='mb-2'>
                            <label htmlFor='collegeName'>College Group</label>
                            <MDBox color="text">
                                <select value={collegeGroupId} onChange={handlcolGroup} className='form-control' style={{ WebkitAppearance: "auto" }}>
                                            <option>Select collegeGroup</option>
                                            {collegeGroups.map((collegeGroup) => (
                                                <option value={collegeGroup.collegeGroupId}>{collegeGroup.collegeGroupName}</option>
                                            ))}
                                </select>
                                </MDBox>
                            </div>
                            <div className='mb-2'>
                            <label htmlFor='collegeName'>College Location</label>
                            <MDBox color="text">
                                <select value={colLocationId} onChange={handlcolLocation} className='form-control' style={{ WebkitAppearance: "auto" }}>
                                            <option>Select collegeLocation</option>
                                            {collegeLocations.map((collegeLocation) => (
                                                <option value={collegeLocation.collegeLocationId}>{collegeLocation.collegeLocationName}</option>
                                            ))}
                                </select>
                                </MDBox>
                            </div>
                            </div> 
                            <div className='row' style={{ width: "900px" }}>
                                <div className='mb-1'>
                                    <label htmlFor='collegeName'>Name</label>
                                    <input
                                        className={`form-control ${errors.collegeName ? 'is-invalid' : ''}`}
                                        type='text'
                                        name='collegeName'
                                        {...register("collegeName", { required: true, minLength: 5, maxLength: 100 })}
                                    />
                                </div>
                                <div className='mb-1'>
                                    <label htmlFor='phoneNumber'>Phone Number</label>
                                    <input
                                        className={`form-control ${errors.phoneNumber ? 'is-invalid' : ''}`}
                                        type='text'
                                        name='phoneNumber'
                                        {...register("phoneNumber", { required: true, pattern:{value: /^[0-9]{10}$/i } })}
                                    />
                                </div>

                            </div>
                            <div className='mb-3'>
                                <MDButton variant="outlined" type="submit" color="info" size="small">
                                    Add
                                </MDButton>
                            </div>
                        </form>
                    </div>
                </Card>
            </Grid>
            </Grid>
        </DashboardLayout>
    );
}

export default AddCollege;
