import { useState, useEffect } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { baseUrl, baseUrladmin } from "../../../assets/js/config/config.js";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import axios from "axios";
import { useLocation } from "react-router-dom";
import "../style.css";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

function EditCollegeGroup() {
    const AccessToken = localStorage.getItem("accessToken");
    const { register, handleSubmit, formState: { errors }, setValue, watch } = useForm();
    const navigate = useNavigate();
    const [editdata, setEditdata] = useState([]);
    const { state } = useLocation();

    useEffect(() => {
        // Fetch the data based on the collegeGroupId
        axios({
            method: "get",
            url: baseUrladmin + "college-groups" + '/' + state.collegeGroupId,
            config: {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json; charset=utf-8",
                },
            },
        })
        .then((response) => {
            setEditdata(response.data.data);
            // Set default values for the form
            setValue('collegeGroupName', response.data.data.collegeGroupName);
            setValue('phoneNumber', response.data.data.phoneNumber);
        })
        .catch((err) => {
            console.log(err.message);
        });
    }, [state.collegeGroupId]);

    const onSubmit = (data) => {
        // Prepare the data to be sent for update
        const bodyFormData = {
            "collegeGroupName": data.collegeGroupName || editdata.collegeGroupName,  // Update only if changed
            "phoneNumber": data.phoneNumber || editdata.phoneNumber,  // Update only if changed
        };

        axios({
            method: "put",
            url: baseUrladmin + "college-groups" + '/' + state.collegeGroupId,
            data: bodyFormData,
            config: {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json; charset=utf-8",
                },
            },
        })
        .then((data) => {
            if (data.data.status === 200) {
                alert("College Group Updated Successfully");
                navigate("/getCollegeGroup");
            } else {
                alert(data.data.message);
                window.location.reload(false);
            }
        })
        .catch((err) => {
            console.log(err.message);
        });
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Grid container spacing={6}>
                <Grid item xs={12}>
                    <Card>
                        <MDBox
                            mx={2}
                            mt={3}
                            py={3}
                            px={2}
                            variant="gradient"
                            bgColor="info"
                            borderRadius="lg"
                            coloredShadow="info"
                        >
                            <MDTypography variant="h6" color="white">
                                Edit CollegeGroup
                            </MDTypography>
                        </MDBox>
                        <div className="App container fpd col-6">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="row" style={{ width: "900px" }}>
                                    <div className="mb-1">
                                        <label htmlFor="collegeGroupName">Name</label>
                                        <input
                                            className={`form-control ${errors.collegeGroupName ? 'is-invalid' : ''}`}
                                            type="text"
                                            name="collegeGroupName"
                                            {...register("collegeGroupName", { required: true })}
                                        />
                                    </div>
                                    <div className="mb-1">
                                        <label htmlFor="phoneNumber">Phone Number</label>
                                        <input
                                            className={`form-control ${errors.phoneNumber ? 'is-invalid' : ''}`}
                                            type="text"
                                            name="phoneNumber"
                                            {...register("phoneNumber", { 
                                                required: true, 
                                                pattern: { value: /^[0-9]{10}$/i }
                                            })}
                                        />
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <MDButton variant="outlined" type="submit" color="info" size="small">
                                        Update
                                    </MDButton>
                                </div>
                            </form>
                        </div>
                    </Card>
                </Grid>
            </Grid>
        </DashboardLayout>
    );
}

export default EditCollegeGroup;
