import { useState, useEffect } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import { baseUrl, baseUrladmin } from "../../../assets/js/config/config.js";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import axios from "axios";
import { useLocation } from "react-router-dom";
import "../style.css";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";

function AddNewDevice() {
  const AccessToken = localStorage.getItem("accessToken");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const [collegeGroups, setCollegeGroups] = useState([]);
  const [collegeGroupId, setcolGroup] = useState(localStorage.getItem("collegeGroupId") || "");
  const [collegeLocations, setCollegeLocations] = useState([]);
  const [colLocationId, setcolLocation] = useState(localStorage.getItem("colLocationId") || "");
  const [colleges, setColleges] = useState([]);
  const [collegeId, setCollegeId] = useState(localStorage.getItem("collegeId") || "");
  const [Deviceclassgroups, setDeviceclassgroups] = useState([]);
  const [devicegroup, setdeviceGroup] = useState(localStorage.getItem("devicegroup") || "");

  const handlcolGroupSelect = (e) => {
    setcolGroup(e.target.value);
    localStorage.setItem("collegeGroupId", e.target.value); 
    fetchCollegeLocations(e.target.value);
  };
  const handlcolLocation = (e) => {
    setcolLocation(e.target.value);
    localStorage.setItem("colLocationId", e.target.value); 
    fetchColleges(e.target.value);
  };
  const handlecollege = (e) => {
    setCollegeId(e.target.value);
    localStorage.setItem("collegeId", e.target.value); 
  };
  const handleSelect = (e) => {
    setdeviceGroup(e.target.value);
    localStorage.setItem("devicegroup", e.target.value); 
  };
  const fetchCollegeGroups = () => {
    fetch(baseUrladmin + "college-groups", {
      headers: {
        Authorization: `Bearer ${AccessToken}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 200) {
          setCollegeGroups(data.data);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  const fetchCollegeLocations = (colGroup) => {
    let colGroup1 =  localStorage.getItem("collegeGroupId") || colGroup ;
    fetch(baseUrladmin + "college-locations/college-group/" + colGroup1, {
      headers: {
        Authorization: `Bearer ${AccessToken}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 200) {
          setCollegeLocations(data.data);
          console.log("col", collegeLocations);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  const fetchColleges = (colLoc) => {
    let colLoc1 =  localStorage.getItem("colLocationId") || colLoc ;
    fetch(baseUrladmin + "college-master/college-location/" + colLoc1, {
      headers: {
        Authorization: `Bearer ${AccessToken}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 200) {
          setColleges(data.data);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  const fetchDeviceClassGroup = () => {
    fetch(baseUrladmin + "deviceclassgroup/findAllDeviceClassGroups", {
      headers: {
        Authorization: `Bearer ${AccessToken}`,
        "Content-type": "application/json; charset=UTF-8",
        companyId: localStorage.getItem("companyId"),
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setDeviceclassgroups(data.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  useEffect(() => {
    fetchCollegeGroups();
    fetchCollegeLocations();
    fetchColleges();
    fetchDeviceClassGroup();
  }, []);
  const onSubmit = (data) => {
    const bodyFormData = {
      deviceName: data.deviceName,
      deviceAddress: data.deviceAddress,
      collegeId: collegeId,
      deviceClassGroupId: devicegroup,
    };
    axios({
      method: "post",
      url: baseUrladmin + "device-master",
      data: bodyFormData,
      config: {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8",
          companyId: localStorage.getItem("companyId"),
        },
      },
    })
      .then((data) => {
        if (data.data.status == 200) {
          alert("Device Added Successfully");
          navigate("/getNewDevice");
        } else {
          alert(data.data.message);
          window.location.reload(false);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card>
            <MDBox
              mx={2}
              mt={3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white">
                Add Device
              </MDTypography>
            </MDBox>
            <div className="App container fpd col-6">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row" style={{ width: "900px" }}>
                  <div className="mb-1">
                    <label htmlFor="collegeGroupId">College Group</label>
                    <select
                      value={collegeGroupId}
                      name="collegeGroupId"
                      onChange={handlcolGroupSelect}
                      className={`form-control ${
                        errors.collegeGroupId ? "is-invalid" : ""
                      }`}
                      style={{ WebkitAppearance: "auto" }}
                    >
                      <option>Select collegeGroup</option>
                      {collegeGroups.map((collegeGroup) => (
                        <option value={collegeGroup.collegeGroupId}>
                          {collegeGroup.collegeGroupName}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="mb-1">
                    <label htmlFor="collegeName">College Location</label>
                    <MDBox color="text">
                      <select
                      value={colLocationId}
                        onChange={handlcolLocation}
                        className="form-control"
                        style={{ WebkitAppearance: "auto" }}
                      >
                        <option>Select collegeLocation</option>
                        {collegeLocations.map((collegeLocation) => (
                          <option value={collegeLocation.collegeLocationId}>
                            {collegeLocation.collegeLocationName}
                          </option>
                        ))}
                      </select>
                    </MDBox>
                  </div>
                  <div className="mb-1">
                    <label htmlFor="college">College</label>
                    <MDBox color="text">
                      <select
                        value = {collegeId}
                        onChange={handlecollege}
                        className="form-control"
                        style={{ WebkitAppearance: "auto" }}
                      >
                        <option>Select college</option>
                        {colleges.map((college) => (
                          <option value={college.collegeId}>
                            {college.collegeName}
                          </option>
                        ))}
                      </select>
                    </MDBox>
                  </div>
                </div>
                <div className="row" style={{ width: "900px" }}>
                  <div className="mb-1">
                    <label htmlFor="deviceClassGroupName">DeviceType</label>
                    <MDBox color="text">
                    <select 
                    value = {localStorage.getItem("devicegroup")}
                    onChange={handleSelect} className="form-control" style={{ width: "294px" }}>
                    <option>Select DeviceType</option>
                      {Deviceclassgroups.map((Devcg) => (
                        <option value={Devcg.deviceClassGroupId}>
                          {Devcg.deviceClassGroupName}
                        </option>
                      ))}
                    </select>
                    </MDBox>
                  </div>
                  <div className="mb-1">
                    <label htmlFor="deviceName">Device Name</label>
                    <input
                      className={`form-control ${
                        errors.deviceName ? "is-invalid" : ""
                      }`}
                      type="text"
                      name="deviceName"
                      {...register("deviceName", { required: true })}
                    />
                  </div>
                  <div className="mb-1">
                    <label htmlFor="deviceAddress">Device Address</label>
                    <input
                      className={`form-control ${
                        errors.deviceAddress ? "is-invalid" : ""
                      }`}
                      type="text"
                      name="deviceAddress"
                      maxLength={16}
                      minLength={16}
                      {...register("deviceAddress", {
                        required: true,
                        pattern: {
                          value: /^[0-9A-F]{16}?$/i,
                        },
                      })}
                    />
                  </div>
                </div>
                <div className="mb-3">
                  <MDButton
                    variant="outlined"
                    type="submit"
                    color="info"
                    size="small"
                  >
                    Add
                  </MDButton>
                </div>
              </form>
            </div>
          </Card>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
}

export default AddNewDevice;
