import { useEffect } from "react";

// react-router-dom components
import { useLocation, NavLink } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// Material Dashboard 2 React example components
import SidenavCollapse from "examples/Sidenav/SidenavCollapse";

// Custom styles for the Sidenav
import SidenavRoot from "examples/Sidenav/SidenavRoot";
import sidenavLogoLabel from "examples/Sidenav/styles/sidenav";
import Dashboard from "layouts/dashboard";
import POSHistory from "layouts/tables/poshistory";
import DISHistory from "layouts/tables/dishistory";
import BalHistory from "layouts/tables/balhistory";
import PhoneStatusHistory from "layouts/tables/phonestatushistory";
import Devicedata from "layouts/tables/devicedata";
import CollegeGroup from "layouts/tables/collegeGroup/collegeGroup"
import Customers from "layouts/tables/customer/customer";
import Transactionreport from "layouts/tables/transactionreport";
import Unknownphonestatus from "layouts/tables/unknownphonestatus/unknownphonestatus";
import POSMobiles from "layouts/tables/posmobiles/posmobiles";
import Selftransfer from "layouts/tables/selftransfer";
import MyTasks from "layouts/tables/mytasks";
import LowSignalDevices from "layouts/tables/lowsignal";
import CollegeLocation from "layouts/tables/collegeLocation/collegeLocation"
import College from "layouts/tables/colleges/college"

// Material Dashboard 2 React context
import {
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
} from "context";
import Messages from "layouts/tables/balhistory";
import Phonerechargealert from "layouts/tables/phonerechargealert";
import CampusManager from "layouts/tables/campus";
import UserManagement from "layouts/tables/userManagement";
import NewDevice from "layouts/tables/deviceMaster/NewDevice";

// import EditUser from "layouts/tables/userManagement/editUser";

function Sidenav({ color, brand, brandName, ...rest }) {
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode } =
    controller;
  const location = useLocation();
  const collapseName = location.pathname.replace("/", "");

  let textColor = "white";

  if (transparentSidenav || (whiteSidenav && !darkMode)) {
    textColor = "dark";
  } else if (whiteSidenav && darkMode) {
    textColor = "inherit";
  }

  const closeSidenav = () => setMiniSidenav(dispatch, true);

  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
      setTransparentSidenav(
        dispatch,
        window.innerWidth < 1200 ? false : transparentSidenav
      );
      setWhiteSidenav(
        dispatch,
        window.innerWidth < 1200 ? false : whiteSidenav
      );
    }

    /** 
    The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener("resize", handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location]);

  const roles = localStorage.getItem("roles");
  if (roles) {
    if (roles.includes("ROLE_ADMIN")) {
      var routes1 = [
        {
          type: "collapse",
          name: "Dashboard",
          key: "dashboard",
          icon: <Icon fontSize="small">dashboard</Icon>,
          route: "/dashboard",
          component: <Dashboard />,
        },
        {
          type: "collapse",
          name: "User  Management",
          key: "userManagement",
          icon: <Icon fontSize="small">table_view</Icon>,
          route: "/user-management",
          component: <UserManagement />,
        },
        {
          type: "collapse",
          name: "Self Transfer",
          key: "Selftransfer",
          icon: <Icon fontSize="small">table_view</Icon>,
          route: "/Selftransfer",
          component: <Selftransfer />,
        },
        {
          type: "collapse",
          name: "Transaction Report",
          key: "Transaction Report",
          icon: <Icon fontSize="small">table_view</Icon>,
          route: "/transactionreport",
          component: <Transactionreport />,
        },
        {
          type: "collapse",
          name: "MyTasks",
          key: "MyTasks",
          icon: <Icon fontSize="small">table_view</Icon>,
          route: "/mytasks",
          component: <MyTasks />,
        },
        {
          type: "collapse",
          name: "Low Signal Phones",
          key: "Low Signal Phones",
          icon: <Icon fontSize="small">table_view</Icon>,
          route: "/lowsignalphones",
          component: <LowSignalDevices />,
        },
        {
          type: "collapse",
          name: "Raw Messages",
          key: "Raw Messages",
          icon: <Icon fontSize="small">table_view</Icon>,
          route: "/messages",
          component: <Messages />,
        },
        // {
        //   type: "collapse",
        //   name: "Devices",
        //   key: "viewdevice",
        //   icon: <Icon fontSize="small">table_view</Icon>,
        //   route: "/devicedata",
        //   component: <Devicedata />,
        // },
        {
          type: "collapse",
          name: "College Group",
          key: "CollegeGroup",
          icon: <Icon fontSize="small">table_view</Icon>,
          route: "/getCollegeGroup",
          component: <CollegeGroup />,
        },
        {//
          type: "collapse",
          name: "College Location",
          key: "getCollegelocation",
          icon: <Icon fontSize="small">table_view</Icon>,
          route: "/getCollegelocation",
          component: <CollegeLocation />,
        },
        {
          type: "collapse",
          name: "College",
          key: "getCollege",
          icon: <Icon fontSize="small">table_view</Icon>,
          route: "/getCollege",
          component: <College />,
        },
        
        {
          type: "collapse",
          name: "Devices",
          key: "getNewDevice",
          icon: <Icon fontSize="small">table_view</Icon>,
          route: "/getNewDevice",
          component: <NewDevice />,
        },
        {
          type: "collapse",
          name: "Campus  Management",
          key: "campusManagement",
          icon: <Icon fontSize="small">table_view</Icon>,
          route: "/campus-management",
          component: <CampusManager />,
        },
        {
          type: "collapse",
          name: "POS History",
          key: "poshistory",
          icon: <Icon fontSize="small">table_view</Icon>,
          route: "/poshistory",
          component: <POSHistory />,
        },
        {
          type: "collapse",
          name: "DIS History",
          key: "dishistory",
          icon: <Icon fontSize="small">table_view</Icon>,
          route: "/dishistory",
          component: <DISHistory />,
        },
        {
          type: "collapse",
          name: "Bal History",
          key: "balhistory",
          icon: <Icon fontSize="small">table_view</Icon>,
          route: "/balhistory",
          component: <BalHistory />,
        },
        {
          type: "collapse",
          name: "Phone Status",
          key: "phonestatushistory",
          icon: <Icon fontSize="small">table_view</Icon>,
          route: "/phonestatushistory",
          component: <PhoneStatusHistory />,
        },
        {
          type: "collapse",
          name: "Unknownphonestatus",
          key: "Unknownphonestatus",
          icon: <Icon fontSize="small">table_view</Icon>,
          route: "/Unknownphonestatus",
          component: <Unknownphonestatus />,
        },
        {
          type: "collapse",
          name: "POS Mobiles",
          key: "POSMobiles",
          icon: <Icon fontSize="small">table_view</Icon>,
          route: "/POSMobiles",
          component: <POSMobiles />,
        },
        {
          type: "collapse",
          name: "Phonerechargealert",
          key: "Phonerechargealert",
          icon: <Icon fontSize="small">table_view</Icon>,
          route: "/Phonerechargealert",
          component: <Phonerechargealert />,
        },
      ];
    } else if (roles.includes("ROLE_COLLEGEGROUP")) {
      var routes1 = [
        {
          type: "collapse",
          name: "Dashboard",
          key: "dashboard",
          icon: <Icon fontSize="small">dashboard</Icon>,
          route: "/dashboard",
          component: <Dashboard />,
        },
        // {
        //   type: "collapse",
        //   name: "Transaction Report",
        //   key: "Transaction Report",
        //   icon: <Icon fontSize="small">table_view</Icon>,
        //   route: "/transactionreport",
        //   component: <Transactionreport />,
        // },
        // {
        //   type: "collapse",
        //   name: "POS",
        //   key: "viewdevice",
        //   icon: <Icon fontSize="small">table_view</Icon>,
        //   route: "/devicedata",
        //   component: <Devicedata />,
        // },
        // {
        //   type: "collapse",
        //   name: "Colleges",
        //   key: "Customers",
        //   icon: <Icon fontSize="small">table_view</Icon>,
        //   route: "/customers",
        //   component: <Customers />,
        // },
        // {
        //   type: "collapse",
        //   name: "POS History",
        //   key: "poshistory",
        //   icon: <Icon fontSize="small">table_view</Icon>,
        //   route: "/poshistory",
        //   component: <POSHistory />,
        // },
        // {
        //   type: "collapse",
        //   name: "DIS History",
        //   key: "dishistory",
        //   icon: <Icon fontSize="small">table_view</Icon>,
        //   route: "/dishistory",
        //   component: <DISHistory />,
        // },
        // {
        //   type: "collapse",
        //   name: "Bal History",
        //   key: "balhistory",
        //   icon: <Icon fontSize="small">table_view</Icon>,
        //   route: "/balhistory",
        //   component: <BalHistory />,
        // },
        // {
        //   type: "collapse",
        //   name: "Phone Status",
        //   key: "phonestatushistory",
        //   icon: <Icon fontSize="small">table_view</Icon>,
        //   route: "/phonestatushistory",
        //   component: <PhoneStatusHistory />,
        // },
      ];
    } else {
      var routes1 = [
        {
          type: "collapse",
          name: "Dashboard",
          key: "dashboard",
          icon: <Icon fontSize="small">dashboard</Icon>,
          route: "/dashboard",
          component: <Dashboard />,
        },
        // {
        //   type: "collapse",
        //   name: "POS",
        //   key: "viewdevice",
        //   icon: <Icon fontSize="small">table_view</Icon>,
        //   route: "/devicedata",
        //   component: <Devicedata />,
        // },
        // {
        //   type: "collapse",
        //   name: "POS History",
        //   key: "poshistory",
        //   icon: <Icon fontSize="small">table_view</Icon>,
        //   route: "/poshistory",
        //   component: <POSHistory />,
        // },
        // {
        //   type: "collapse",
        //   name: "DIS History",
        //   key: "dishistory",
        //   icon: <Icon fontSize="small">table_view</Icon>,
        //   route: "/dishistory",
        //   component: <DISHistory />,
        // },
        // {
        //   type: "collapse",
        //   name: "Bal History",
        //   key: "balhistory",
        //   icon: <Icon fontSize="small">table_view</Icon>,
        //   route: "/balhistory",
        //   component: <BalHistory />,
        // },
        // {
        //   type: "collapse",
        //   name: "Phone Status",
        //   key: "phonestatushistory",
        //   icon: <Icon fontSize="small">table_view</Icon>,
        //   route: "/phonestatushistory",
        //   component: <PhoneStatusHistory />,
        // },
      ];
    }
  } else {
    var routes1 = [
      {
        type: "collapse",
        name: "Dashboard",
        key: "dashboard",
        icon: <Icon fontSize="small">dashboard</Icon>,
        route: "/dashboard",
        component: <Dashboard />,
      },
      // {
      //   type: "collapse",
      //   name: "POS",
      //   key: "viewdevice",
      //   icon: <Icon fontSize="small">table_view</Icon>,
      //   route: "/devicedata",
      //   component: <Devicedata />,
      // },
      // {
      //   type: "collapse",
      //   name: "POS History",
      //   key: "poshistory",
      //   icon: <Icon fontSize="small">table_view</Icon>,
      //   route: "/poshistory",
      //   component: <POSHistory />,
      // },
      // {
      //   type: "collapse",
      //   name: "DIS History",
      //   key: "dishistory",
      //   icon: <Icon fontSize="small">table_view</Icon>,
      //   route: "/dishistory",
      //   component: <DISHistory />,
      // },
      // {
      //   type: "collapse",
      //   name: "Bal History",
      //   key: "balhistory",
      //   icon: <Icon fontSize="small">table_view</Icon>,
      //   route: "/balhistory",
      //   component: <BalHistory />,
      // },
      // {
      //   type: "collapse",
      //   name: "Phone Status",
      //   key: "phonestatushistory",
      //   icon: <Icon fontSize="small">table_view</Icon>,
      //   route: "/phonestatushistory",
      //   component: <PhoneStatusHistory />,
      // },
    ];
  }

  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  const renderRoutes = routes1.map(
    ({ type, name, icon, title, noCollapse, key, href, route }) => {
      let returnValue;

      if (type === "collapse") {
        returnValue = href ? (
          <Link
            href={href}
            key={key}
            target="_blank"
            rel="noreferrer"
            sx={{ textDecoration: "none" }}
          >
            <SidenavCollapse
              name={name}
              icon={icon}
              active={key === collapseName}
              noCollapse={noCollapse}
            />
          </Link>
        ) : (
          <NavLink key={key} to={route}>
            <SidenavCollapse
              name={name}
              icon={icon}
              active={key === collapseName}
            />
          </NavLink>
        );
      } else if (type === "title") {
        returnValue = (
          <MDTypography
            key={key}
            color={textColor}
            display="block"
            variant="caption"
            fontWeight="bold"
            textTransform="uppercase"
            pl={3}
            mt={2}
            mb={1}
            ml={1}
          >
            {title}
          </MDTypography>
        );
      } else if (type === "divider") {
        returnValue = (
          <Divider
            key={key}
            light={
              (!darkMode && !whiteSidenav && !transparentSidenav) ||
              (darkMode && !transparentSidenav && whiteSidenav)
            }
          />
        );
      }

      return returnValue;
    }
  );

  return (
    <SidenavRoot
      {...rest}
      variant="permanent"
      ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}
    >
      <MDBox pt={3} pb={1} px={4} textAlign="center">
        <MDBox
          display={{ xs: "block", xl: "none" }}
          position="absolute"
          top={0}
          right={0}
          p={1.625}
          onClick={closeSidenav}
          sx={{ cursor: "pointer" }}
        >
          <MDTypography variant="h6" color="secondary">
            <Icon sx={{ fontWeight: "bold" }}>close</Icon>
          </MDTypography>
        </MDBox>
        <MDBox component="h6" display="flex" alignItems="center">
          {brand && (
            <MDBox component="img" src={brand} alt="Brand" width="2rem" />
          )}
          <MDBox
            width={!brandName && "100%"}
            sx={(theme) => sidenavLogoLabel(theme, { miniSidenav })}
          >
            <MDTypography
              component="h6"
              variant="button"
              fontWeight="medium"
              color={textColor}
            >
              SVG PayPhone
            </MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
      <Divider
        light={
          (!darkMode && !whiteSidenav && !transparentSidenav) ||
          (darkMode && !transparentSidenav && whiteSidenav)
        }
      />
      <List>{renderRoutes}</List>
      {/* <MDBox p={2} mt="auto">
        <MDButton
          component="a"
          href="https://www.creative-tim.com/product/material-dashboard-pro-react"
          target="_blank"
          rel="noreferrer"
          variant="gradient"
          color={sidenavColor}
          fullWidth
        >
          upgrade to pro
        </MDButton>
      </MDBox> */}
    </SidenavRoot>
  );
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
  color: "info",
  brand: "",
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
  ]),
  brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
};

export default Sidenav;
