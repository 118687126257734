import { useState, useEffect } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import { baseUrl, baseUrladmin } from "../../../assets/js/config/config.js";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import axios from "axios";
import { useLocation } from "react-router-dom";
import "../style.css";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";

function AddCollegeLocation() {
    const AccessToken = localStorage.getItem("accessToken");
    const { register, handleSubmit, formState: { errors } } = useForm();
    const navigate = useNavigate();
    const [collegeGroups, setCollegeGroups] = useState([]);
    const [collegeGroupId, setcolGroup] = useState(localStorage.getItem("collegeGroupId") || "");
    const handlSelect = (e) => {
        setcolGroup(e.target.value);
        localStorage.setItem("collegeGroupId", e.target.value);
     };
     useEffect(() => {
     fetch(baseUrladmin + "college-groups", {
        headers: {
            Authorization: `Bearer ${AccessToken}`,
            "Content-type": "application/json; charset=UTF-8",
        },
    })
        .then((response) => response.json())
        .then((data) => {
            if (data.status == 200) {
                setCollegeGroups(data.data);
                // setcolGroup(data.data[0].collegeGroupId) 
            }
        })
        .catch((err) => {
            console.log(err.message);
        });
    }, []);

    const onSubmit = (data) => {
        const bodyFormData = {
            "collegeLocationName": data.collegeLocationName,
            "phoneNumber": data.phoneNumber,
            "collegeGroupId": collegeGroupId
        };
        axios({
            method: "post",
            url: baseUrladmin + "college-locations",
            data: bodyFormData,
            config: {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json; charset=utf-8",
                    Authorization: `Bearer ${AccessToken}`,
                },
            },
        })
            .then((data) => {
                if (data.data.status == 200) {
                    alert("College Location added Successfully");
                    navigate("/getCollegeLocation");
                }
                else {
                    alert(data.data.message);
                    window.location.reload(false);
                }
            })
            .catch((err) => {
                console.log(err.message);
            });
    }
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Grid container spacing={6}><Grid item xs={12}>
                <Card>
                    <MDBox
                        mx={2}
                        mt={3}
                        py={3}
                        px={2}
                        variant="gradient"
                        bgColor="info"
                        borderRadius="lg"
                        coloredShadow="info"
                    >
                        <MDTypography variant="h6" color="white">
                            Add CollegeLocation
                        </MDTypography>
                    </MDBox>
                    <div className='App container fpd col-6'>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className='row' style={{ width: "900px" }}>
                                
                               <div className='mb-1'>
                                    <label htmlFor='collegeGroupId'>College Group</label>
                                    <select   value={collegeGroupId}  name='collegeGroupId' onChange={handlSelect} 
                                            className={`form-control`}
                                             style={{ WebkitAppearance: "auto" }} >
                                            <option>Select collegeGroup</option>
                                            {collegeGroups.map((collegeGroup) => (
                                                <option value={collegeGroup.collegeGroupId}>{collegeGroup.collegeGroupName}</option>
                                            ))}
                                </select>
                                </div>
                                <div className='mb-1'>
                                    <label htmlFor='collegeLocationName'>Name</label>
                                    <input
                                        className={`form-control ${errors.collegeLocationName ? 'is-invalid' : ''}`}
                                        type='text'
                                        name='collegeLocationName'
                                        {...register("collegeLocationName", { required: true, minLength: 5, maxLength: 100 })}
                                    />
                                </div>
                                <div className='mb-1'>
                                    <label htmlFor='phoneNumber'>Phone Number</label>
                                    <input
                                        className={`form-control ${errors.phoneNumber ? 'is-invalid' : ''}`}
                                        type='text'
                                        name='phoneNumber'
                                        {...register("phoneNumber", { required: true, pattern: {value: /^[0-9]{10}$/i } })}
                                    />
                                </div>
                            </div>
                            <div className='mb-3'>
                                <MDButton variant="outlined" type="submit" color="info" size="small">
                                    Add
                                </MDButton>
                            </div>
                        </form>
                    </div>
                </Card>
            </Grid>
            </Grid>
        </DashboardLayout>
    );
}

export default AddCollegeLocation;
