import { useState, useEffect } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import { baseUrl, baseUrladmin } from "../../../assets/js/config/config.js";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import axios from "axios";
import { useLocation } from "react-router-dom";
import "../style.css";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";

function EditCollege() {
    const AccessToken = localStorage.getItem("accessToken");
    const { register, handleSubmit, formState: { errors } } = useForm();
    const navigate = useNavigate();
    const [editdata, setEditdata] = useState([]);
    const { state } = useLocation();

    useEffect(() => {
            axios({
                method: "get",
                url: baseUrladmin + "college-master"+'/'+state.collegeId,
                config: {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json; charset=utf-8",
                        companyId: localStorage.getItem("companyId")
                    },
                },
            }).then((response) => response)
                .then((data) => {
                    setEditdata(data.data.data);
                })
                .catch((err) => {
                    console.log(err.message);
                });
    }, []);
    const onSubmit = (data) => {
        const bodyFormData = {
            "collegeName": data.collegeName ? data.collegeName : editdata.collegeName,
            "phoneNumber": data.phoneNumber ? data.phoneNumber : editdata.phoneNumber,
            "collegeId": "154610ca-da01-4a53-b5f8-87df22620a83"
        };
        axios({
            method: "put",
            url: baseUrladmin + "college-master"+'/'+state.collegeId,
            data: bodyFormData,
            config: {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json; charset=utf-8",
                    companyId: localStorage.getItem("companyId")
                },
            },
        })
            .then((data) => {
                if (data.data.status == 200) {
                    alert("College Updated Successfully");
                    navigate("/getCollege");
                }
                else {
                    alert(data.data.message);
                    window.location.reload(false);
                }
            })
            .catch((err) => {
                console.log(err.message);
            });
    }
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Grid container spacing={6}><Grid item xs={12}>
                <Card>
                    <MDBox
                        mx={2}
                        mt={3}
                        py={3}
                        px={2}
                        variant="gradient"
                        bgColor="info"
                        borderRadius="lg"
                        coloredShadow="info"
                    >
                        <MDTypography variant="h6" color="white">
                            Edit College
                        </MDTypography>
                    </MDBox>
                    <div className='App container fpd col-6'>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className='row' style={{ width: "900px" }}>
                                <div className='mb-1'>
                                    <label htmlFor='collegeName'>Name</label>
                                    <input
                                        className={`form-control ${errors.collegeName ? 'is-invalid' : ''}`}
                                        type='text'
                                        name='collegeName'  defaultValue={editdata.collegeName}
                                        {...register("collegeName", { required: true })}
                                    />
                                </div>
                                <div className='mb-1'>
                                    <label htmlFor='phoneNumber'>Phone Number</label>
                                    <input
                                        className={`form-control ${errors.phoneNumber ? 'is-invalid' : ''}`}
                                        type='text'
                                        name='phoneNumber' defaultValue={editdata.phoneNumber}
                                        {...register("phoneNumber", { required: true, pattern: {value: /^[0-9]{10}$/i } })}
                                    />
                                </div>
                            </div>
                            <div className='mb-3'>
                                <MDButton variant="outlined" type="submit" color="info" size="small">
                                    Update
                                </MDButton>
                            </div>
                        </form>
                    </div>
                </Card>
            </Grid>
            </Grid>
        </DashboardLayout>
    );
}

export default EditCollege;
